<template>
  <div class="flex items-start justift-start gap-1">
    <input
      type="checkbox"
      :id="name"
      :name="name"
      :checked="modelValue"
      class="input-light-check hj:input-dark-check mr-3"
      @input="$emit('update:modelValue', $event.target.checked)" />
    <label v-if="label" :for="name" class="font-graphik text-gray-500 hj:text-f-white">{{ label }}</label>
    <slot />
  </div>
</template>

<script>
export default {
  name: "BengieInputCheckbox",
  props: {
    modelValue: Boolean,
    label: String,
    name: String,
  },
  emits: ["update:modelValue"],
}
</script>

<style scoped></style>
